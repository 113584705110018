import { MatSnackBar } from "@angular/material/snack-bar";
import { environment } from "src/environment";

export class NotificationBar {

  constructor(private snackBar: MatSnackBar) { }

  show(message: string) {
    var action = null;
    this.snackBar.open(message, action, {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: environment.notificationDuration,
    });
  }

  showCustomDuration(message: string, duration: number) {
    var action = null;
    this.snackBar.open(message, action, {
      horizontalPosition: "center",
      verticalPosition: "top",
      duration: duration,
    });
  }

  showUnknownError() {
    this.show("An error has occurred, please try again later!");
  }
}